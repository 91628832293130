<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
      >
        <base-material-card
          title="Informations Promotion"
        >
          <template>
            <div class="text-h3 font-weight-light">
              <v-simple-table>
                <thead>
                  <tr>
                    <th class="primary--text">
                      Id
                    </th>
                    <th class="primary--text">
                      Nom
                    </th>
                    <th class="primary--text">
                      Réduction
                    </th>
                    <th class="primary--text">
                      Montant minimum d'achat
                    </th>
                    <th class="primary--text">
                      Quantité minimum
                    </th>
                    <th class="primary--text">
                      Eligibilité du client
                    </th>
                    <th class="primary--text">
                      Limite d'utilisation
                    </th>
                    <th class="primary--text">
                      Date de début
                    </th>
                    <th class="primary--text">
                      Date de fin
                    </th>
                    <th class="primary--text">
                      Statut
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td> {{ codePromotion[0].id_promotion }} </td>
                    <td> {{ codePromotion[0].nom }} </td>
                    <td> {{ codePromotion[0].reduction }} </td>
                    <td> {{ codePromotion[0].minimum_achat }} </td>
                    <td> {{ codePromotion[0].minimum_quantite }} </td>
                    <td> {{ codePromotion[0].eligibilite }} </td>
                    <td> {{ codePromotion[0].limite_utilisation }} </td>
                    <td> {{ codePromotion[0].date_debut }} </td>
                    <td> {{ codePromotion[0].date_fin }} </td>
                    <td> {{ codePromotion[0].statut }} </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </template>
        </base-material-card>
      </v-col>
    </v-row>
    <v-btn
      color="error"
      @click="SupprimerPromotion()"
    >
      Supprimer
    </v-btn>
  </v-container>
</template>

 <script>
  // Utilities
  import axios from 'axios'
  import VueAxios from 'vue-axios'
  import Vue from 'vue'
  import {
    mapState,
  } from 'vuex'

  Vue.use(VueAxios, axios)

  export default {
    name: 'DashboardCoreDrawer',

    props: {
      expandOnHover: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      montantChoisi: '',
      updateOk: false,
      KOupdate: false,
      colorSnackbarSuccess: '',
      colorSnackbarError: '',
      direction: 'bottom right',
      codePromotion: [],
      idCodePromo: '',
      // statut_commande : 1 en attente de paiement, 2 payé, 3 annulé, 4 supprimé , 5 remboursé
      montant: [],
    }),
    computed: {
      ...mapState(['barColor', 'barImage']),
      drawer: {
        get () {
          return this.$store.state.drawer
        },
        set (val) {
          this.$store.commit('SET_DRAWER', val)
        },
      },
      computedItems () {
        return this.items.map(this.mapItem)
      },
      parsedDirection () {
        return this.direction.split(' ')
      },
      profile () {
        return {
          avatar: true,
          title: this.$t('site_title'),
        }
      },
    },
    mounted () {
      this.idCodePromo = this.$route.params.idCodePromotion
      Vue.axios.get('https://api.espace-jantes.com/getOnePromotion', {
        params: { id_promotion: this.$route.params.idCodePromotion },
      })
        .then((response) => {
          response.data.forEach(element => {
            this.codePromotion.push(element)
          })
        })
        .catch(error => console.log(error))
    },
    methods: {
      mapItem (item) {
        return {
          ...item,
          children: item.children ? item.children.map(this.mapItem) : undefined,
          title: this.$t(item.title),
        }
      },
    },
  }
</script>
<style scoped>
  input[type=text] {
    border: 2px solid black !important;
    border-radius : 4px;
    margin-right : 15px;
  }
</style>
